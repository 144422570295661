import { useState } from "react";
import { Link } from "@inertiajs/react";
import {
  CardItemProps,
  GalleryCardItemProps,
  ShopCartProps,
} from "./component";
import { Minus, Plus, Trash2, Heart } from "lucide-react";

import { useAppDispatch, setBagItems, deleteBagItem } from "@/redux/store";

import { Dialog, DialogContent } from "@/Components/ui/dialog";

import { SwiperCard } from "./SwiperCard";

export function ProductCard({
  href,
  images = [],
  image,
  title,
  subtitle,
  status,
  price,
  sidePriceText,
  isPlaceholderImage,
}: CardItemProps) {
  const [cardHover, setCardHover] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState<string>(
    image ? image : images[0]
  );

  return (
    <Link
      href={href!}
      onMouseEnter={() => setCardHover(true)}
      onMouseLeave={() => setCardHover(false)}>
      <div className="w-100">
        <img src={currentImage} alt={title} className="card-image block" />
        <div className="cursor-pointer">
          {isPlaceholderImage && cardHover && images!.length > 1 ? (
            <div className="flex space-x-2 pt-[15px]">
              {images!.length > 0 &&
                images!.map((imageItem, index) => (
                  <img
                    key={index}
                    src={imageItem}
                    alt={title}
                    width={50}
                    onMouseEnter={() => setCurrentImage(imageItem)}
                  />
                ))}
            </div>
          ) : (
            <>
              {title && (
                <div className="pt-[15px]">
                  <h2 className="mb-2 text-[16px] font-[700] leading-[21.6px] text-black">
                    {title}
                  </h2>
                  <p className="mb-1 text-[16px] font-[400] leading-[21.6px] text-gray-700">
                    {subtitle}
                  </p>
                  {status && (
                    <p className="mb-1 text-[16px] font-[400] leading-[21.6px] text-gray-700">
                      {status}
                    </p>
                  )}
                </div>
              )}
            </>
          )}
          {price && (
            <div className="my-4 flex items-baseline">
              {sidePriceText && (
                <p className="pr-[8px] text-[12px] font-[400] text-black">
                  {sidePriceText}
                </p>
              )}
              <p className="text-[16px] font-semibold text-black">{price}</p>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
}

export function CategoryCard({
  href,
  image,
  title,
  buttonText,
}: CardItemProps) {
  return (
    <Link href={href!}>
      <div className="w-100 relative overflow-hidden bg-black bg-opacity-50 shadow-md">
        <img src={image} alt={title} className="card-image block" />
        <div className="absolute inset-0 mx-8 my-7 flex cursor-pointer flex-col justify-end space-y-4 pt-[15px]">
          <h2 className="mb-2 text-[24px] font-[700] leading-[21.6px] text-white">
            {title}
          </h2>
          <button className="w-fit rounded-full bg-white pb-[8px] pl-[45px] pr-[45px] pt-[8px] text-[14px] font-[600] text-black">
            {buttonText}
          </button>
        </div>
      </div>
    </Link>
  );
}

export function GalleryCard({ title, images }: GalleryCardItemProps) {
  const [openGallery, setOpenGallery] = useState<boolean>(false);

  return (
    <>
      {images?.length > 0 &&
        images?.map((item, index) => (
          <div className="w-100 h-100 relative" key={index}>
            <img
              src={item.image}
              alt={title}
              className="h-full w-full object-cover"
            />
            <button
              onClick={() => setOpenGallery(true)}
              className="absolute inset-0 flex items-center justify-center opacity-0 transition-opacity duration-300 hover:opacity-100">
              <Plus
                className="rounded-full bg-gray-200 p-4 text-black hover:bg-gray-100"
                size={50}
              />
            </button>
          </div>
        ))}
      <Dialog open={openGallery} onOpenChange={setOpenGallery}>
        <DialogContent className="max-w-screen block max-h-full">
          <div className="container block px-4 py-4">
            <SwiperCard
              type="product"
              cardData={images}
              numberSliderPerView={[1, 2, 2]}
              isNavigation={true}
              navigationPosition={"center"}
              isPagination={false}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export function ShopCard({
  bagItems,
  size,
  isCount,
  isRemove,
  isFavorite,
}: ShopCartProps) {
  const dispatch = useAppDispatch();

  const minValue = 0;
  const maxValue = 10;

  const handleIncrementCounter = (id: string, amount: number) => {
    if (amount < maxValue) {
      const bagItems = [
        {
          id: id,
          amount: amount + 1,
        },
      ];

      dispatch(setBagItems(bagItems));
    }
  };

  const handleDecrementCounter = (id: string, amount: number) => {
    if (amount > minValue) {
      const newAmount = amount - 1;
      const bagItems = [
        {
          id: id,
          amount: amount - 1,
        },
      ];

      if (newAmount == 0) {
        handleRemoveItem(id);
      } else {
        dispatch(setBagItems(bagItems));
      }
    }
  };

  const handleRemoveItem = (id: string) => {
    dispatch(deleteBagItem(id));
  };

  let cardClassName: string;
  let imageSize: string;
  let titleSize: string;
  let priceSize: string;
  let subTitleSize: string;
  let countIconSize: number;
  let countClassName: string;
  let proccessIconSize: number;

  if (size == "normal") {
    cardClassName = "flex py-4";
    imageSize = "h-24 w-24 rounded-md";
    titleSize = "text-[14px] font-[600] leading-[16.1px] tracking-[0.1px]";
    priceSize = "text-[14px] font-[600]";
    subTitleSize = "text-[13px] text-fire-gray font-[400] leading-[14.3px]";
    countIconSize = 14;
    countClassName = "mx-3 lg:mx-2";
    proccessIconSize = 18;
  } else if (size == "large") {
    cardClassName = "flex py-4 border-b border-gray-200";
    imageSize = "h-24 w-24 md:h-40 md:w-40 rounded-none";
    titleSize = "text-[16px] font-[600] leading-[20px] tracking-[0.1px]";
    priceSize = "text-[15px] font-[400]";
    subTitleSize =
      "text-[15px] text-fire-gray font-[400] leading-[18.75px] text-balance";
    countIconSize = 14;
    countClassName = "my-2 mx-1 sm:mx-3 lg:mx-2";
    proccessIconSize = 24;
  } else {
    cardClassName = "flex py-2";
    imageSize = "h-16 w-16 rounded-md";
    titleSize = "text-[14px] font-[600] leading-[16.1px] tracking-[0.1px]";
    priceSize = "text-[14px] font-[600]";
    subTitleSize = "text-[13px] text-fire-gray font-[400] leading-[14.3px]";
    countIconSize = 14;
    countClassName = "mx-3 lg:mx-2";
    proccessIconSize = 18;
  }

  // checkout sayfasındaki card için
  const onlyShowCard = !isCount && !isRemove && !isFavorite;

  return (
    <ul role="list" className="-my-6 flow-root py-6">
      {bagItems.map((item, index) => (
        <li key={index} className={cardClassName}>
          <div
            className={`${imageSize} ${onlyShowCard ? "relative" : "overflow-hidden"} flex-shrink-0 border border-gray-200`}>
            {onlyShowCard && (
              <div className="absolute -right-2 -top-3 flex h-6 w-6 items-center justify-center rounded-full bg-[#0000008f] text-[12px] font-[600] text-white">
                {item.amount}
              </div>
            )}
            <img
              src={item.image}
              alt={item.title}
              className="h-full w-full object-cover object-center"
            />
          </div>
          <div
            className={`ml-4 flex flex-1 flex-col ${size == "large" && "ml-2 justify-center lg:ml-6"}`}>
            <div>
              <div className="flex items-baseline justify-between text-base font-medium text-gray-900">
                <Link href={"/product"} className={`${titleSize} text-start`}>
                  {item.title}
                </Link>
                <p className={`ml-4 text-right ${priceSize}`}>
                  ${item.price * item.amount}
                </p>
              </div>
              <p
                className={`mt-1 w-fit text-start tracking-normal lg:w-[83%] ${subTitleSize}`}>
                {item.subTitle.map((subItem, subIndex) => (
                  <>
                    {subItem}
                    {subIndex == item.subTitle.length - 1 ? "" : " / "}
                  </>
                ))}
              </p>
            </div>
            <div
              className={`flex items-end text-sm ${size == "large" ? "mt-4 items-center justify-start space-x-8" : "flex-1 justify-between"} `}>
              {isCount && (
                <div className="flex w-min items-center space-x-4 overflow-hidden rounded-full border border-gray-200 bg-white hover:border-black">
                  <button
                    onClick={() => {
                      handleDecrementCounter(item.id, item.amount);
                    }}>
                    <Minus size={countIconSize} className={countClassName} />
                  </button>
                  <p className="w-4 items-center justify-center">
                    {item.amount}
                  </p>
                  <button
                    className="mx-2"
                    onClick={() => {
                      handleIncrementCounter(item.id, item.amount);
                    }}>
                    <Plus size={countIconSize} className={countClassName} />
                  </button>
                </div>
              )}
              <div className="flex">
                {isFavorite && (
                  <button onClick={() => handleRemoveItem(item.id)}>
                    <Heart size={proccessIconSize} className="mr-4" />
                  </button>
                )}
                {isRemove && (
                  <button onClick={() => handleRemoveItem(item.id)}>
                    <Trash2 size={proccessIconSize} />
                  </button>
                )}
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}
